import React, { useState, useContext, useEffect, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { ScrollContext } from "./scroll_provider";
import SectionLayout from "./layouts/section_layout";
import { Header, SubHeader, Paragraph, MarkdownRenderer } from "./shared";

// Decorative bullet for lists.
const ListItemBullet = ({ filledIn }) => {
  return (
    <div
      className={`w-3 h-3 border-2 inline-block mr-2 border-blue-light ${
        filledIn ? "bg-blue-light" : ""
      }`}
    />
  );
};


const List = ({ items }) => {
  return (
    <ul className="text-white font-body">
      {items.map((item, index) => {
        return (
          <li key={index} className="mt-2">
            <ListItemBullet filledIn={index % 2 === 0} />
            <MarkdownRenderer>{item}</MarkdownRenderer>
          </li>
        );
      })}
    </ul>
  );
};

const ServiceItem = ({ title, icon, reverse = false, children }) => {
  return (
    <div className="flex flex-wrap items-center justify-center mt-2 lg:mt-20">
      {/* Icon */}
      <div
        className={`w-full lg:w-3/6 mt-6 sm:mt-4 lg:mt-0 ${
          reverse ? "lg:order-2" : ""
        }`}
      >
        <div className="w-2/6 mx-auto">{icon}</div>
      </div>

      {/* Copy */}
      <div
        className={`w-full lg:w-3/6 mt-6 sm:mt-4 lg:mt-0 ${
          reverse ? "lg:order-1" : ""
        }`}
      >
        <h3 className="text-2xl text-gray-light font-body">{title}</h3>

        {children}
      </div>
    </div>
  );
};

const ServiceItemIcon = ({ visible, animationDirection, ...rest }) => {
  const animationClass =
    animationDirection === "right"
      ? "animation-back-in-right"
      : "animation-back-in-left";

  return (
    <Img
      className={`${animationClass} ${visible ? "lg:visible" : "lg:hidden"}`}
      {...rest}
    />
  );
};

export default function ServicesSection() {
  const data = useStaticQuery(graphql`
    query {
      siteYaml {
        services {
          header
          subheader
          description
          products
          products_description
          products_bullets
          custom_solutions
          custom_solutions_description
          custom_solutions_bullet_1
          custom_solutions_bullet_2
          custom_solutions_bullet_3
          custom_solutions_bullet_4
          consulting
          consulting_description
          consulting_bullet_1
          consulting_bullet_2
          consulting_bullet_3
          consulting_bullet_4
        }
      }
      gearsIcon: file(relativePath: { eq: "images/icons/services/gears.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blocksIcon: file(
        relativePath: { eq: "images/icons/services/blocks.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      loopIcon: file(relativePath: { eq: "images/icons/services/loop.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const {
    header,
    subheader,
    description,
    products,
    products_description,
    products_bullets,
    custom_solutions,
    custom_solutions_description,
    custom_solutions_bullet_1,
    custom_solutions_bullet_2,
    custom_solutions_bullet_3,
    custom_solutions_bullet_4,
    consulting,
    consulting_description,
    consulting_bullet_1,
    consulting_bullet_2,
    consulting_bullet_3,
    consulting_bullet_4,
  } = data.siteYaml.services;

  const { fluid: gearsIconFluid } = data.gearsIcon.childImageSharp;
  const { fluid: blocksIconFluid } = data.blocksIcon.childImageSharp;
  const { fluid: loopIconFluid } = data.loopIcon.childImageSharp;

  const [productsLogoVisible, setProductsLogoVisible] = useState(false);
  const [customSolutionsLogoVisible, setCustomSolutionsLogoVisible] = useState(
    false
  );
  const [consultingLogoVisible, setConsultingLogoVisible] = useState(false);

  // // Scroll context.
  const scrollContext = useContext(ScrollContext);
  const { top, bottom } = scrollContext.items.services;

  // Calculate section heights
  const sectionHeight = bottom - top;
  const sectionThirdHeight = sectionHeight / 3;
  const windowHeight = useRef(0);

  useEffect(() => {
    windowHeight.current = window.screen.height;
  });

  // Animate the service logos once the corresponding
  // third of the screen has been scrolled past.
  if (
    !productsLogoVisible &&
    top <= windowHeight.current - sectionThirdHeight * 1
  )
    setProductsLogoVisible(true);

  if (
    !customSolutionsLogoVisible &&
    top <= windowHeight.current - sectionThirdHeight * 2
  )
    setCustomSolutionsLogoVisible(true);

  if (
    !consultingLogoVisible &&
    top <= windowHeight.current - sectionThirdHeight * 3
  )
    setConsultingLogoVisible(true);

  return (
    <SectionLayout name="services" className="bg-blue-dark">
      <div className="container px-2 mx-auto">
        <div className="text-center">
          <Header content={header} className="text-white" />
          <SubHeader content={subheader} className="text-blue-light" />

          <Paragraph
            className="w-5/6 mx-auto text-white lg:w-3/6"
            content={description}
          />
        </div>

        {/* Products */}
        <ServiceItem
          title={products}
          icon={
            <ServiceItemIcon
              fluid={gearsIconFluid}
              alt="Products Icon"
              animationDirection="right"
              visible={productsLogoVisible}
            />
          }
          reverse={true}
        >
          <Paragraph
            className="text-white text-md"
            content={products_description}
          />
          <List items={products_bullets} />
        </ServiceItem>

        {/* Custom solutions */}
        <ServiceItem
          title={custom_solutions}
          icon={
            <ServiceItemIcon
              fluid={blocksIconFluid}
              alt="Custom Solutions Icon"
              animationDirection="left"
              visible={customSolutionsLogoVisible}
            />
          }
        >
          <Paragraph
            className="text-white text-md"
            content={custom_solutions_description}
          />
          <List
            items={[
              custom_solutions_bullet_1,
              custom_solutions_bullet_2,
              custom_solutions_bullet_3,
              custom_solutions_bullet_4,
            ]}
          />
        </ServiceItem>

        {/* Consulting */}
        <ServiceItem
          title={consulting}
          icon={
            <ServiceItemIcon
              fluid={loopIconFluid}
              alt="Consulting Icon"
              animationDirection="right"
              visible={consultingLogoVisible}
            />
          }
          reverse={true}
        >
          <Paragraph
            className="text-white text-md"
            content={consulting_description}
          />
          <List
            items={[
              consulting_bullet_1,
              consulting_bullet_2,
              consulting_bullet_3,
              consulting_bullet_4,
            ]}
          />
        </ServiceItem>
      </div>
    </SectionLayout>
  );
}

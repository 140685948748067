import React, { useState, useContext, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { ScrollContext } from "./scroll_provider";
import SectionLayout from "./layouts/section_layout";
import {
  Header,
  SubHeader,
  Paragraph,
  Button,
  Link,
  MarkdownRenderer,
} from "./shared";

export default function AboutSection() {
  const data = useStaticQuery(graphql`
    query {
      siteYaml {
        about {
          header
          subheader
          paragraph_1
          paragraph_2
          cta_long
          cta_short
        }
      }
      file(relativePath: { eq: "images/who-we-are.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const {
    siteYaml: {
      about: {
        header,
        subheader,
        paragraph_1,
        paragraph_2,
        cta_long,
        cta_short,
      },
    },
    file: {
      childImageSharp: { fluid: aboutUsFluid },
    },
  } = data;

  // Scroll context.
  const scrollContext = useContext(ScrollContext);
  const { top } = scrollContext.items.about;

  // Logo
  const [logoVisible, setLogoVisible] = useState(false);

  // Show the logo once when 50% of the 'about'
  // section is visible.
  useEffect(() => {
    if (!logoVisible && top <= window.innerHeight / 2) setLogoVisible(true);
  }, [top, logoVisible]);

  return (
    <SectionLayout name="about" className="bg-white">
      <div className="container flex flex-wrap items-center mx-auto">
        {/* Content */}
        <div className="w-full px-2 xl:w-3/6">
          <Header content={header} className="text-off-black" />
          <SubHeader content={subheader} className="text-blue-light" />
          <Paragraph
            content={<MarkdownRenderer>{paragraph_1}</MarkdownRenderer>}
            className="text-gray-text"
          />
          <Paragraph content={paragraph_2} className="text-gray-text" />
        </div>

        {/* Equation image */}
        <div className="w-full px-2 mt-4 xl:w-3/6">
          <div
            className={`max-w-lg mx-auto ${
              logoVisible ? "animation-fade-in-down" : "invisible"
            }`}
          >
            <Img fluid={aboutUsFluid} alt="About DemandByte" />
          </div>
        </div>
      </div>

      {/* Contact us links */}
      <div className="px-2 mt-6 text-center">
        <Paragraph className="text-black text-opacity-75">
          {cta_long} <Link href="#contact" content="DemandByte" />.
        </Paragraph>

        <Button
          href="#contact"
          content={cta_short}
          className="w-5/6 sm:w-3/6 md:w-2/6 xl:w-3/12 2xl:w-2/12"
        />
      </div>
    </SectionLayout>
  );
}

import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const randomBoolean = () => Math.random() >= 0.5

// Decorative square under the main logo.
const Square = ({ active }) => {
  return (
    <div
      className={`w-3 h-3 border-2 transition ease-in duration-500
            ${active ? "bg-blue-light border-blue-light" : "border-white"}`}
    />
  )
}

// Decorative square container which randomly
// 'activates' squares.
const SquareContainer = () => {
  const generateSquares = () => [...Array(8)].map(() => randomBoolean())

  const [squares, setSquares] = useState(generateSquares())

  useEffect(() => {
    const interval = setInterval(() => {
      setSquares(generateSquares())
    }, 2500)
    return () => clearInterval(interval)
  })

  return (
    <div className="flex justify-between mx-1 mt-3">
      {squares.map((active, i) => (
        <Square key={i} active={active} />
      ))}
    </div>
  )
}

export default function HeroSection() {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo-large.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      funnelIcon: file(relativePath: { eq: "images/icons/funnel.png" }) {
        childImageSharp {
          fixed(width: 39, height: 69) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      siteYaml {
        hero {
          description
        }
      }
    }
  `)

  const {
    logo: {
      childImageSharp: { fluid: logoFluid },
    },
    funnelIcon: {
      childImageSharp: { fixed: funnelFixed },
    },
  } = data
  const { description } = data.siteYaml.hero

  return (
    // The hero banner takes up the full height of the screen and has padding
    // to show the menu (either top or bottom). A min height ensures all mobile
    // orientations show the entire section.
    <main className="h-screen pt-8 lg:pt-0 lg:pb-16 bg-blue-dark font-headers min-h-xl">
      {/* Circuit board background (only visible on LG and above devices). */}
      <div className="absolute top-0 hidden w-3/12 h-full ml-24 lg:block bg-circuit" />

      {/* Hero content */}
      <div className="container flex flex-col items-center h-full mx-auto text-center justify-evenly 2xl:max-h-80">
        {/* Logo */}
        <div className="w-6/12 sm:w-3/12 md:w-3/12 lg:w-3/12 xl:w-2/12">
          {/* Make it a bit smaller on 2XL devices. */}
          <div className="mx-auto 2xl:w-10/12">
            <Img fluid={logoFluid} alt="DemandByte Logo" loading="eager" fadeIn={false} />

            <SquareContainer />
          </div>
        </div>

        {/* Header 
                    - z-index to make sure the text is above the circuit board background
                */}
        <h1 className="px-2 text-4xl font-light leading-tight text-white lg:z-50 sm:w-8/12 md:w-9/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12 md:text-5xl">
          We Build <span className="text-blue-light">Smart</span> Marketing
          Technology
        </h1>

        {/* Tagline 
                    - z-index to make sure the text is above the circuit board background
                */}
        <p className="px-2 text-xl text-gray-300 lg:z-50 sm:w-8/12 md:w-9/12 lg:w-7/12 xl:w-5/12 md:text-2xl">
          {description}
        </p>

        {/* Funnel icon */}
        <a
          href="#about"
          className="hidden md:block animation-floating hover:opacity-25"
        >
          <Img fixed={funnelFixed} alt="Learn more" />
        </a>
      </div>
    </main>
  )
}

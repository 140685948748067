import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ScrollProvider from "../components/scroll_provider"
import SEO, { OrganizationStructuredData } from "../components/seo"
import Navbar from "../components/navbar"
import HeroSection from "../components/hero_section"
import AboutSection from "../components/about_section"
import ServicesSection from "../components/services_section"
import ProcessSection from "../components/process_section"
import ClientsSection from "../components/clients_section"
import ProductsSection from "../components/products_section"
import ContactUsSection from "../components/contact_us_section"
import Footer from "../components/footer"
import FontLoader from "../components/font_loader"

export default function Home({location}) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
          description
          contactPhone
        }
      }
    }
  `)

  const {
    site: {
      siteMetadata: { title, siteUrl, contactPhone },
    },
  } = data

  return (
    <>
      <FontLoader preload={["montserrat-300", "montserrat-400"]} />
      
      <SEO location={location}>
        <OrganizationStructuredData
          title={title}
          siteUrl={siteUrl}
          contactPhone={contactPhone}
        />
      </SEO>

      <div className="font-headers">
        <ScrollProvider>
          <HeroSection />
          <Navbar />
          <ProductsSection />
          <ServicesSection />
          <ProcessSection />
          <ClientsSection />
          <AboutSection />
          <ContactUsSection />
          <section className='bg-blue-dark'>
            <hr className="container h-2 mx-auto border-none bg-blue-light" />
          </section>
          <Footer />
        </ScrollProvider>
      </div>
    </>
  )
}

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SectionLayout from "./layouts/section_layout"
import { Header, SubHeader } from "./shared"

export default function ClientsSection() {
  const data = useStaticQuery(graphql`
    query {
      siteYaml {
        clients {
          header
          subheader
        }
      }
      images: allFile(
        filter: { relativeDirectory: { eq: "images/client_logos" } }
        sort: { fields: [base], order: ASC }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const {
    siteYaml: {
      clients: { header, subheader },
    },
    images: { edges },
  } = data

  return (
    <SectionLayout name="clients" className="bg-red-light">
      <div className="container px-2 mx-auto">
        <div className="text-center">
          <Header content={header} className="text-white" />
          <SubHeader content={subheader} className="text-white" />
        </div>

        <div className="flex flex-wrap justify-between mt-10">
          {edges.map(edge => {
            const {
              name,
              childImageSharp: { fluid },
            } = edge.node

            return (
              <div key={name} className="w-1/3 mt-4 xl:w-1/5">
                <div className="mx-2 bg-white border-8 border-gray-400 xl:mx-4">
                  <Img fluid={fluid} alt={name} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </SectionLayout>
  )
}

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import SectionLayout from "./layouts/section_layout"
import { Header, SubHeader } from "./shared"
import Img from "gatsby-image"

// Used to prevent the class name from
// being purged during compilation.
const productColors = {
    "email-governor": "bg-email-governor",
    "campaign-launchpad": "bg-campaign-launchpad",
    "campaign-approver": "bg-campaign-approver",
    telequeue: "bg-telequeue",
}

const Product = ({ title, description, icon, name }) => {
    const productBackgroundColor = productColors[name]

    return (
        <Link
            to={`/products/${name}`}
            className="w-11/12 mx-auto sm:w-4/6 md:mx-0 md:w-3/6 lg:w-2/6 lg:mx-2"
        >
            <div
                className={`sm:mx-4 lg:mx-0 mt-4 pb-2 text-center relative ${productBackgroundColor}`}
            >
                {/* Description */}
                <div
                    className={`absolute w-full h-full hover:pt-12 px-4 z-40 opacity-0 hover:opacity-90
          text-white font-medium text-xl transition-all duration-300 ${productBackgroundColor}`}
                >
                    {description}
                </div>

                {/* Title */}
                <h2 className="py-6 text-xl font-semibold text-center text-white">
                    {title}{" "}
                    <small className="text-xs font-normal align-text-top">
                        ™
                    </small>
                </h2>

                {/* Icon */}
                <div className="w-3/6 mx-auto lg:mb-4">
                    <Img fluid={icon} alt={description} />
                </div>
            </div>
        </Link>
    )
}

export default function ProductsSection() {
    const data = useStaticQuery(graphql`
        query {
            siteYaml {
                products {
                    header
                    subheader
                }
            }
            products: allProductsYaml(sort: { fields: [title], order: [ASC] }) {
                nodes {
                    name
                    title
                    description
                }
            }
            icons: allFile(
                filter: {
                    relativeDirectory: { eq: "images/icons/products" }
                    extension: { eq: "png" }
                }
                sort: { fields: [name], order: [ASC] }
            ) {
                nodes {
                    childImageSharp {
                        fluid(maxWidth: 200, maxHeight: 200) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `)

    const {
        siteYaml: {
            products: { header, subheader },
        },
        products: { nodes: products },
        icons: { nodes: icons },
    } = data

    return (
        <SectionLayout name="products" className="bg-white">
            <div className="container px-2 mx-auto">
                <div className="text-center">
                    <Header content={header} className="text-black" />
                    <SubHeader
                        content={subheader}
                        className="text-blue-light"
                    />
                </div>

                <div className="flex flex-wrap max-w-5xl mx-auto lg:justify-center">
                    {products.map(({ name, ...rest }, i) => (
                        <Product
                            key={i}
                            {...rest}
                            icon={icons[i].childImageSharp.fluid}
                            name={name}
                        />
                    ))}
                </div>
            </div>
        </SectionLayout>
    )
}

import React, { useState, useContext, useEffect, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { ScrollContext } from "./scroll_provider";

// Returns the font size set on the HTML element.
// const getRootSizeInPixels = () =>
//   parseInt(getComputedStyle(document.documentElement).fontSize);

// Returns whether or not a given element
// is partially visible in the viewport.
const isElementVisible = (elementScrollPosition, offset = 0) => {
  if (!elementScrollPosition) return false;

  const { top, bottom } = elementScrollPosition;

  return top - offset <= 0 && bottom - offset >= 0;
};

// Returns the menu item who's related
// container is currently visible.
const getActiveMenuItem = (menuItems, scrollItems, offset) =>
  menuItems.find((element) =>
    isElementVisible(scrollItems[element.link], offset)
  );

const MenuBookend = ({ navInverse }) => (
  <span
    className={`hidden px-10 pt-px pb-px transition duration-500 ease-in-out lg:block ${
      navInverse ? "bg-gray-300" : "bg-gray-700"
    }`}
  />
);

export default function Navbar() {
  const data = useStaticQuery(graphql`
    query NavbarQuery {
      site {
        siteMetadata {
          menuItems {
            name
            link
            outline
            inverseIfActive
          }
        }
      }
      darkLogo: file(relativePath: { eq: "images/logo-dark.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      lightLogo: file(relativePath: { eq: "images/logo-light.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: { menuItems },
    },
    darkLogo,
    lightLogo,
  } = data;

  // Scroll position context
  const scrollContext = useContext(ScrollContext);
  const { items: scrollItems, y: scrollY } = scrollContext;

  // Mobile Menu Toggle
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => setMenuOpen(!menuOpen);

  // Calculate the navbar and hero banner heights.
  const navbarRef = useRef();
  const adjustedHeroBannerHeight = useRef(0);

  const navbarHeight = navbarRef.current ? navbarRef.current.offsetHeight : 0

  useEffect(() => {
    adjustedHeroBannerHeight.current = window.innerHeight - navbarHeight;
  });

  // Determine which section is being viewed currently.
  const activeMenuItem = getActiveMenuItem(menuItems, scrollItems, navbarHeight);

  // The nav changes background colors when the active menu item
  // is a contrasting color.
  const navInverse = activeMenuItem && activeMenuItem.inverseIfActive;

  // The nav becomes sticky in large viewports after the hero banner
  // is scrolled past.
  const navSticky = scrollY >= adjustedHeroBannerHeight.current;

  // Show the mobile logo once half of the hero banner has
  // been scrolled past.
  const showLogo = scrollY >= adjustedHeroBannerHeight.current / 2;

  return (
    <nav
      ref={navbarRef}
      className={`w-full fixed top-0 transition duration-500 ease-in-out z-50
            ${navSticky ? "" : "lg:static lg:-mt-16"}
            ${navInverse ? "bg-white" : "bg-blue-dark"}`}
    >
      <div className="px-2 mx-auto sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-center h-16 lg:justify-between">
          {/* Mobile menu button */}
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              aria-label="Main menu"
              aria-expanded="false"
              onClick={handleMenuToggle}
              className="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md text-blue-light hover:text-white hover:bg-gray-700 focus:outline-none"
            >
              {/* Closed */}
              <svg
                className={`${menuOpen ? "hidden" : "block"} h-6 w-6`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/* Open */}
              <svg
                className={`${menuOpen ? "block" : "hidden"} h-6 w-6`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Mobile logo */}
          <div
            className={`flex items-center justify-center w-full sm:hidden transition duration-500 ease-in-out ${
              showLogo ? "opacity-100" : "opacity-0"
            }`}
          >
            <div
              className={`absolute transition duration-500 ease-in-out sm:hidden align-middle ${
                navInverse ? "opacity-100" : "opacity-0"
              }`}
            >
              <Img fixed={darkLogo.childImageSharp.fixed} />
            </div>
            <div
              className={`absolute transition duration-500 ease-in-out sm:hidden align-middle ${
                navInverse ? "opacity-0" : "opacity-100"
              }`}
            >
              <Img fixed={lightLogo.childImageSharp.fixed} />
            </div>
          </div>

          {/* Desktop menu */}
          <div className="items-center justify-center flex-1 hidden sm:items-stretch sm:justify-start sm:flex">
            <div className="flex-1 sm:ml-6">
              <div className="flex items-center justify-evenly">
                <MenuBookend navInverse={navInverse} />
                {menuItems.map(({ name, link, outline }) => {
                  const outlined = !!outline;
                  const active = activeMenuItem && link === activeMenuItem.link;

                  return (
                    <a
                      key={name}
                      href={`#${link}`}
                      className={`py-2 text-lg font-normal leading-5 focus:outline-none 
                                            transition duration-200 ease-in-out 
                                            ${
                                              outlined
                                                ? "border-2 border-blue-light uppercase px-2 md:px-8 lg:px-16"
                                                : "lowercase"
                                            }
                                            ${
                                              outlined && navInverse
                                                ? "bg-blue-light text-white hover:text-gray-light"
                                                : "text-gray-light hover:text-blue-light"
                                            }
                                            ${
                                              active
                                                ? outlined
                                                  ? "text-white"
                                                  : "text-blue-light"
                                                : ""
                                            }`}
                    >
                      {name}
                    </a>
                  );
                })}
                <MenuBookend navInverse={navInverse} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`sm:hidden ${menuOpen ? "" : "hidden"}`}>
        <div className="px-2 pt-2 pb-3">
          <div>
            {menuItems.map(({ name, link }) => {
              const active = activeMenuItem && link === activeMenuItem.link;

              return (
                <a
                  key={name}
                  href={`/#${link}`}
                  onClick={() => {
                    setMenuOpen(false);
                  }}
                  className={`block mt-1 px-3 py-2 rounded-md text-base font-medium
                                    hover:text-blue-light focus:outline-none
                                    transition duration-150 ease-in-out
                                    ${
                                      active
                                        ? "text-blue-light"
                                        : "text-gray-light"
                                    }`}
                >
                  {name}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
}

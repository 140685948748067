import React, { createContext, useState, useLayoutEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

// Returns the current window scroll position.
// E.g. {x: 100, y: 1000}
const getWindowPosition = () => {
  return {
    x: window.scrollX,
    y: window.scrollY,
  };
};

// Return the current scroll position of
// a DOM element.
const getElementPosition = (elementQuery) => {
  const domElement = document.querySelector(elementQuery);

  if (!domElement) return {};

  const { top, bottom } = domElement.getBoundingClientRect();

  return { top, bottom };
};

// Returns an object comprised of keys for each menu
// item and values indicating that item's scroll position.
const getMenuItemPositions = (menuItems) => {
  return menuItems.reduce((acc, link) => {
    return {
      ...acc,
      [link.link]: getElementPosition(`#${link.link}`),
    };
  }, {});
};

export const ScrollContext = createContext();

export default function ScrollProvider({ children }) {
  const data = useStaticQuery(graphql`
    query ScrollProviderMenuQuery {
      site {
        siteMetadata {
          menuItems {
            link
          }
        }
      }
    }
  `);

  const { menuItems } = data.site.siteMetadata;

  const [scrollPosition, setScrollPosition] = useState({});
  const [menuItemsPositions, setMenuItemPositions] = useState(
    menuItems.reduce((acc, link) => {
      return { ...acc, [link.link]: "" };
    }, {})
  );

  useLayoutEffect(() => {
    let scrollThrottleTimeout = null;

    const updatePositions = () => {
      setScrollPosition(getWindowPosition());
      setMenuItemPositions(getMenuItemPositions(menuItems));
    };

    updatePositions();

    // On scroll update the current position
    // and the active menu item.
    const handleScroll = () => {
      updatePositions();

      scrollThrottleTimeout = null;
    };

    const handleThrottledScroll = () => {
      if (scrollThrottleTimeout === null)
        scrollThrottleTimeout = setTimeout(handleScroll, 10);
    };

    window.addEventListener("scroll", handleThrottledScroll);

    return () => window.removeEventListener("scroll", handleThrottledScroll);
  }, [menuItems]);

  const scrollContext = {
    ...scrollPosition,
    items: menuItemsPositions,
  };

  return (
    <ScrollContext.Provider value={scrollContext}>
      {children}
    </ScrollContext.Provider>
  );
}

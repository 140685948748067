import React, { useContext, useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import SectionLayout from "./layouts/section_layout";
import { ScrollContext } from "./scroll_provider";
import { Header, SubHeader, Paragraph } from "./shared";

const ProgressBar = ({ direction, active }) => {
  const rotationClass = direction === "left" ? "lg:rotate-42" : "lg:-rotate-42";

  return (
    <div
      className={`h-24 w-px mx-auto bg-gray-500 lg:relative lg:h-164 lg:bottom-100 lg:transform ${rotationClass}`}
    >
      <div
        className={`h-0 w-3px ${
          active ? "activeProgress" : "inactiveProgress"
        }`}
      />
    </div>
  );
};

const ProcessItem = React.forwardRef(
  ({ icon, title, description, direction, itemActive, children }, ref) => {
    const textDirection = direction === "right" ? "text-right" : "text-left";
    const margin =
      direction === "right" ? "lg:ml-auto lg:mr-0" : "lg:mr-auto lg:ml-0";

    return (
      <div ref={ref} className={`mt-4 lg:mt-0 lg:h-136 ${textDirection}`}>
        <div className={`lg:h-full max-w-xs text-center mx-auto ${margin}`}>
          <div className="w-32 mx-auto">
            <Img fluid={icon} alt={title} />
          </div>

          <h2
            className={`mt-6 text-2xl font-normal font-body ${
              itemActive ? "text-blue-light" : "text-gray-light"
            }`}
          >
            {title}
          </h2>

          <Paragraph content={description} className="text-xs text-gray-text" />
        </div>

        {children}
      </div>
    );
  }
);

const ProcessItems = ({ items }) => {
  const itemsRef = useRef([]);

  // Scroll context.
  const scrollContext = useContext(ScrollContext);
  const { y: windowScrollY } = scrollContext;

  // Calculate how much of the window has been
  // scrolled passed.
  const scrolledPastHeight = useRef(0);

  useEffect(() => {
    const halfWindowHeight = window.innerHeight / 2;
    scrolledPastHeight.current = windowScrollY + halfWindowHeight;
  });

  return items.map((item, i) => {
    const evenItem = i % 2;
    const itemDirection = evenItem ? "right" : "left";
    const progressBarDirection = evenItem ? "left" : "right";

    // Using Infinity so the below "active" comparisons won't
    // return true unless the element has been rendered.
    const itemHeight = itemsRef.current[i]
      ? itemsRef.current[i].offsetHeight
      : Infinity;
    const itemTopOffset = itemsRef.current[i]
      ? itemsRef.current[i].offsetTop
      : Infinity;

    // The item is active once it's top reaches 50%
    // of the screen's height.
    const itemActive = scrolledPastHeight.current >= itemTopOffset;

    // The item progress bar is active once 40% of it's
    // height has been scrolled past.
    const itemProgressBarActive =
      scrolledPastHeight.current >= itemTopOffset + itemHeight * 0.4;

    return (
      <ProcessItem
        ref={(el) => (itemsRef.current[i] = el)}
        key={i}
        icon={item.icon}
        title={item.title}
        description={item.description}
        direction={itemDirection}
        itemActive={itemActive}
      >
        {i + 1 < items.length && (
          <ProgressBar
            direction={progressBarDirection}
            active={itemProgressBarActive}
          />
        )}
      </ProcessItem>
    );
  });
};

export default function ProcessSection() {
  const data = useStaticQuery(graphql`
    query {
      siteYaml {
        process {
          header
          subheader
          strategy
          strategy_description
          design
          design_description
          development
          development_description
          delivery
          delivery_description
          support
          support_description
        }
      }
      strategyIcon: file(
        relativePath: { eq: "images/icons/process/strategy.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      designIcon: file(
        relativePath: { eq: "images/icons/process/design.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      developmentIcon: file(
        relativePath: { eq: "images/icons/process/development.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      deliveryIcon: file(
        relativePath: { eq: "images/icons/process/delivery.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      supportIcon: file(
        relativePath: { eq: "images/icons/process/support.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const {
    header,
    subheader,
    strategy,
    strategy_description,
    design,
    design_description,
    development,
    development_description,
    delivery,
    delivery_description,
    support,
    support_description,
  } = data.siteYaml.process;

  const { fluid: strategyIconFluid } = data.strategyIcon.childImageSharp;
  const { fluid: designIconFluid } = data.designIcon.childImageSharp;
  const { fluid: developmentIconFluid } = data.developmentIcon.childImageSharp;
  const { fluid: deliveryIconFluid } = data.deliveryIcon.childImageSharp;
  const { fluid: supportIconFluid } = data.supportIcon.childImageSharp;

  const items = [
    {
      icon: strategyIconFluid,
      title: strategy,
      description: strategy_description,
    },
    {
      icon: designIconFluid,
      title: design,
      description: design_description,
    },
    {
      icon: developmentIconFluid,
      title: development,
      description: development_description,
    },
    {
      icon: deliveryIconFluid,
      title: delivery,
      description: delivery_description,
    },
    {
      icon: supportIconFluid,
      title: support,
      description: support_description,
    },
  ];

  return (
    <SectionLayout name="process" className="bg-white">
      <div className="container max-w-5xl px-2 mx-auto">
        <div className="text-center">
          <Header content={header} className="text-off-black" />
          <SubHeader content={subheader} className="text-blue-light" />
        </div>

        <ProcessItems items={items} />
      </div>
    </SectionLayout>
  );
}

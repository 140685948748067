import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SectionLayout from "./layouts/section_layout"
import { Header, SubHeader, Link, Paragraph } from "./shared"
import ContactUsFormWrapper from "./contact_us_form_wrapper"

const ContactUsItem = ({ iconFixed, content }) => {
  // Use the image file name as the alternate text.
  const { src } = iconFixed
  const imgAlt = src.match(/^.+\/(.+)\./)[1]

  return (
    <div className="mt-4 first:mt-0 lg:mx-auto lg:w-2/3">
      <Img fixed={iconFixed} className="align-middle" alt={imgAlt} />
      <span className="ml-6 font-medium text-white align-middle md:text-lg">
        {content}
      </span>
    </div>
  )
}

export const contactUsIcon = graphql`
  fragment contactUsIcon on File {
    childImageSharp {
      fixed(height: 40, width: 40) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export default function ContactUsSection() {
  const data = useStaticQuery(graphql`
    query {
      siteYaml {
        contact_us {
          header
          subheader
          email
          address
          phone
          linkedIn
          twitter
          thank_you_header
          thank_you_message
        }
      }
      phoneIcon: file(
        relativePath: { eq: "images/icons/contact_us/phone.png" }
      ) {
        ...contactUsIcon
      }
      addressIcon: file(
        relativePath: { eq: "images/icons/contact_us/address.png" }
      ) {
        ...contactUsIcon
      }
      emailIcon: file(
        relativePath: { eq: "images/icons/contact_us/email.png" }
      ) {
        ...contactUsIcon
      }
      linkedInIcon: file(
        relativePath: { eq: "images/icons/contact_us/linked-in.png" }
      ) {
        ...contactUsIcon
      }
      twitterIcon: file(
        relativePath: { eq: "images/icons/contact_us/twitter.png" }
      ) {
        ...contactUsIcon
      }
    }
  `)

  const {
    siteYaml: {
      contact_us: {
        header,
        subheader,
        email,
        address,
        phone,
        linkedIn,
        twitter,
        thank_you_header,
        thank_you_message,
      },
    },
    phoneIcon: {
      childImageSharp: { fixed: phoneIconFixed },
    },
    addressIcon: {
      childImageSharp: { fixed: addressIconFixed },
    },
    emailIcon: {
      childImageSharp: { fixed: emailIconFixed },
    },
    linkedInIcon: {
      childImageSharp: { fixed: linkedInIconFixed },
    },
    twitterIcon: {
      childImageSharp: { fixed: twitterIconFixed },
    },
  } = data

  return (
    <SectionLayout name="contact" className="bg-blue-dark">
      <div className="container px-2 mx-auto">
        <div className="text-center">
          <Header content={header} className="text-white" />
          <SubHeader content={subheader} className="text-blue-light" />
        </div>

        <div className="flex flex-wrap">
          {/* Form */}
          <div className="w-full lg:w-1/2">
            <ContactUsFormWrapper
              formName="contact"
              submittedMessage={
                <>
                  <SubHeader
                    className="text-white"
                    content={thank_you_header}
                  />
                  <Paragraph
                    className="text-white"
                    content={thank_you_message}
                  />
                </>
              }
            />
          </div>

          {/* Contact Info */}
          <div className="flex flex-col justify-center w-full mt-8 lg:mt-0 lg:w-1/2">
            <ContactUsItem
              iconFixed={emailIconFixed}
              content={<Link href={`mailto:${email}`} content={email} />}
            />

            <ContactUsItem
              iconFixed={phoneIconFixed}
              content={<Link href="tel:6469790013" content={phone} />}
            />

            <ContactUsItem iconFixed={addressIconFixed} content={address} />

            <ContactUsItem
              iconFixed={linkedInIconFixed}
              content={
                <Link href={linkedIn} content="DemandByte" target="_blank" rel="noopener noreferrer"/>
              }
            />

            <ContactUsItem
              iconFixed={twitterIconFixed}
              content={
                <Link href={twitter} content="DemandByte" target="_blank" rel="noopener noreferrer" />
              }
            />
          </div>
        </div>
      </div>
    </SectionLayout>
  )
}
